// 用户相关接口
import request from '@/request/request'

// 存证列表
export const UserListApi = (params) => request.post(`/frame-web/admin/app/user/page`, params);
// 获取用户详情
export const UserDetailApi = (params) => request.get(`/frame-web/admin/app/user/getUserInfo`, { params });
// 修改用户信息
export const UserUpdApi = (params) => request.post(`/frame-web/admin/app/user/upd`, params);
// 认证信息列表
export const UserInfoAutApi = (params) => request.post(`/frame-web/admin/app/user/userInfoAut`, params);

// 侵权列表
export const TortListApi = (params) => request.post(`/frame-web/admin/tort/report/page`, params);
// 侵权详情
export const TortDetailApi = (params) => request.get(`/frame-web/admin/tort/report/getOne`, { params });
// 修改审核状态
export const TortAuditApi = (params) => request.post(`/frame-web/admin/tort/report/audit`, params);
// 根据审核状态,获取下阶段的审核信息下拉框
export const TortSelectApi = (params) => request.get(`/frame-web/admin/tort/report/select`, {params});
