<template>
  <!-- 用户详情 -->
  <div class="positionT0L0">
    <p class="fw600">基本信息</p>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 5 }"
    >
      <a-form-model-item
        label="代理用户"
        prop="agencyFlag"
        labelAlign="left"
        required
      >
        <a-radio-group v-model="form.agencyFlag" name="radioGroup">
          <a-radio :value="0">否</a-radio>
          <a-radio :value="1">是</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="存证次数（剩余）" prop="czQuantity" labelAlign="left">
        <a-input v-model="form.czQuantity" :maxLength="32" />
      </a-form-model-item>
      <a-form-model-item label="登记次数（剩余）" prop="djQuantity" labelAlign="left">
        <a-input v-model="form.djQuantity" :maxLength="32" />
      </a-form-model-item>
      <a-form-model-item label="开始时间" prop="startTime" labelAlign="left">
        <a-date-picker
          v-model="form.startTime"
          format="YYYY-MM-DD HH:mm:ss"
          placeholder="选择开始时间"
          @change="startChange"
        />
      </a-form-model-item>
      <a-form-model-item label="结束时间" prop="endTime" labelAlign="left">
        <a-date-picker
          v-model="form.endTime"
          format="YYYY-MM-DD HH:mm:ss"
          placeholder="选择结束时间"
          @change="endChange"
        />
      </a-form-model-item>
      <FooterToolBar :collapsed="sideCollapsed">
        <a-button class="margin_right60" @click="() => $router.go(-1)">
          返回
        </a-button>
        <a-button type="primary" :disabled="keepDis" @click="keepClick(form)">
          保存
        </a-button>
      </FooterToolBar>
    </a-form-model>
  </div>
</template>

<script>
import { UserDetailApi, UserUpdApi } from "@/request/api/crm.js";
import { message } from "ant-design-vue";
import FooterToolBar from "@/components/FooterToolbar";
import { baseMixin } from "@/store/app-mixin";
export default {
  mixins: [baseMixin],
  components: { FooterToolBar },
  created() {
    this.form.id = this.$route.params.id;
    UserDetailApi({ id: this.form.id }).then(({ code, data }) => {
      if (code == 200) {
        this.form = data;
      }
    });
  },
  data() {
    return {
      form: {
        id: "",
        agencyFlag: 0,
        czQuantity: "",
        djQuantity: "",
        startTime: "",
        endTime: "",
      },
      rules: {
        czQuantity: [
          { required: true, message: "输入的内容不能为空", trigger: "blur" },
        ],
        djQuantity: [
          { required: true, message: "输入的内容不能为空", trigger: "blur" },
        ],
        endTime: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
        startTime: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
      },
      keepDis: false,
    };
  },
  methods: {
    startChange(date, dateString) {
      this.form.startTime = dateString;
    },
    endChange(date, dateString) {
      this.form.endTime = dateString;
    },
    keepClick() {
      this.keepDis = true;
      this.$refs["ruleForm"].validate((result) => {
        if (result) {
          UserUpdApi(this.form).then(({ code, data }) => {
            if (code === 200) {
              message.success("修改成功");
              this.$router.push(`/crm/crmList`);
              this.keepDis = false;
            } else {
              setTimeout(() => {
                this.keepDis = false;
              }, 3000);
            }
          });
        } else {
          message.error("请正确填写表单");
          setTimeout(() => {
            this.keepDis = false;
          }, 3000);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
